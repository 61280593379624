import { default as _91id_93np9cUBoD8vMeta } from "/app/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexz5rNVEhHmmMeta } from "/app/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93FmfkHjZnO6Meta } from "/app/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexVeav0fMWcaMeta } from "/app/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colors5x1fkCfS5sMeta } from "/app/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsbgxB3B0u2hMeta } from "/app/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as index2Jf7sBb4pxMeta } from "/app/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexWOQ5dJrTQEMeta } from "/app/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboard0gLC6OV4ZTMeta } from "/app/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceuQhBY3O1ygMeta } from "/app/pages/[slug]/entrance.vue?macro=true";
import { default as indexq38bJr6bNoMeta } from "/app/pages/[slug]/index.vue?macro=true";
import { default as loginEP3jKIek9dMeta } from "/app/pages/[slug]/login.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93np9cUBoD8vMeta || {},
    component: () => import("/app/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexz5rNVEhHmmMeta || {},
    component: () => import("/app/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93FmfkHjZnO6Meta || {},
    component: () => import("/app/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexVeav0fMWcaMeta || {},
    component: () => import("/app/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colors5x1fkCfS5sMeta || {},
    component: () => import("/app/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsbgxB3B0u2hMeta || {},
    component: () => import("/app/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: index2Jf7sBb4pxMeta || {},
    component: () => import("/app/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexWOQ5dJrTQEMeta || {},
    component: () => import("/app/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/app/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceuQhBY3O1ygMeta || {},
    component: () => import("/app/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/app/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginEP3jKIek9dMeta || {},
    component: () => import("/app/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  }
]